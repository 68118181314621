import React from 'react';
import { Link } from '@reach/router';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'q3-ui-locale';
import TransactionLayout from '../components/TransactionLayout';
import ConfirmationSvg from '../assets/confirmation.svg';

const Acknowledged = () => {
  const { t } = useTranslation();

  return (
    <TransactionLayout
      imgSrc={ConfirmationSvg}
      title="acknowledge"
      buttonText="orders"
      buttonTo="/account/orders"
    >
      <Typography variant="body2" gutterBottom>
        {t('descriptions:acknowledge')}
      </Typography>
      <Box my={2}>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          component={Link}
          to="/account/orders"
        >
          {t('labels:orders')}
        </Button>
      </Box>
    </TransactionLayout>
  );
};

export default Acknowledged;
